import commonAct from '@/commonActionHandle.js';
import vmMachineView from '@/scripts/gpuHub/vmMachines/vmMachines';
import vmMachineApi from '@/api/gpuHub/vmMachines';
import moment from 'moment'
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

export default {
    extends: vmMachineView,
    methods: {
        getElementsList(pageNumber) {
            this.showLoading();
            this.requestParam.isB2bList = (this.$route.name === "B2bGPUHubMachines");
            vmMachineApi.getVmMachineFptList(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.$nextTick(() => { this.hideLoading(); });
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.$nextTick(() => { this.hideLoading(); });
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.$nextTick(() => { this.hideLoading(); });
                commonAct.showError(error);

            });
        },
    }
}